/* Scripts for big full width promo slider. */

var promoSlider = document.querySelector('#big-promo-slider');

/* Set data-attr for original images */
var promoContainerOriginalImg = promoSlider.querySelector('.container-original-images');
var promoOriginalImg = promoContainerOriginalImg.getElementsByTagName('img');
for (var oi = 0; oi < promoOriginalImg.length; oi++) {
    promoOriginalImg[oi].setAttribute('data-promo-slider-original-img', 'promo-slider__' + 'slide-' + oi);
}

/* Set data-attr for blurred images */
var promoContainerBlurredImg = promoSlider.querySelector('.container-blurred-images');
var promoBlurredImg = promoContainerBlurredImg.getElementsByTagName('img');
for (var bi = 0; bi < promoBlurredImg.length; bi++) {
    promoBlurredImg[bi].setAttribute('data-promo-slider-blurred-img', 'promo-slider__' + 'slide-' + bi);
}

/* Set data-attr for text */
var promoContainerText = promoSlider.querySelector('.container-text');
var promoText = promoContainerText.getElementsByClassName('text');
for (var t = 0; t < promoText.length; t++) {
    promoText[t].setAttribute('data-promo-slider-text', 'promo-slider__' + 'slide-' + t);
}

var promoContainerControls = promoSlider.querySelector('.container-controls');
var promoChevronLeft = promoContainerControls.querySelector('.chevron.left');
var promoChevronRight = promoContainerControls.querySelector('.chevron.right');

/* Set data-attr for dot paginator controls */
var promoContainerDots = promoSlider.querySelector('.container-dots');
var promoDots = promoContainerDots.getElementsByTagName('span');
for (var d = 0; d < promoDots.length; d++) {
    promoDots[d].setAttribute('data-promo-slider-dot', 'promo-slider__' + 'slide-' + d);
    /* Add dots EventListener */
    var dot = promoDots[d];
    dot.addEventListener('click', function () {
        showSlideByNumber(this.getAttribute('data-promo-slider-dot'))
    });
}

/* Add chevrons(arrows) EventListener */
promoChevronLeft.addEventListener('click', showPrevSlide);
promoChevronRight.addEventListener('click', showNextSlide);

function showNextSlide() {
    var activeOriginalImg = promoSlider.querySelector('.container-original-images img.active');
    var activeBlurredImg = promoSlider.querySelector('.container-blurred-images img.active');
    var activeText = promoSlider.querySelector('.container-text .text.active');
    var activeDot = promoSlider.querySelector('.container-dots span.active');
    var nextOriginalImg = activeOriginalImg.nextElementSibling;
    var nextBlurredImg = activeBlurredImg.nextElementSibling;
    var nextText = activeText.nextElementSibling;
    var nextDot = activeDot.nextElementSibling;
    if (activeOriginalImg === promoSlider.querySelector(".container-original-images").lastElementChild) {
        nextOriginalImg = promoSlider.querySelector(".container-original-images").firstElementChild;
        nextBlurredImg = promoSlider.querySelector(".container-blurred-images").getElementsByTagName("img")[0];
        nextText = promoSlider.querySelector(".container-text").firstElementChild;
        nextDot = promoSlider.querySelector('.container-dots').firstElementChild;
    }
    activeOriginalImg.classList.remove('slide-in-left');
    activeOriginalImg.classList.remove('slide-in-right');
    activeOriginalImg.classList.remove('slide-out-left');
    activeOriginalImg.classList.remove('slide-out-right');

    nextOriginalImg.classList.remove('slide-in-left');
    nextOriginalImg.classList.remove('slide-in-right');
    nextOriginalImg.classList.remove('slide-out-left');
    nextOriginalImg.classList.remove('slide-out-right');


    activeBlurredImg.classList.remove('slide-in-left');
    activeBlurredImg.classList.remove('slide-in-right');
    activeBlurredImg.classList.remove('slide-out-left');
    activeBlurredImg.classList.remove('slide-out-right');

    nextBlurredImg.classList.remove('slide-in-left');
    nextBlurredImg.classList.remove('slide-in-right');
    nextBlurredImg.classList.remove('slide-out-left');
    nextBlurredImg.classList.remove('slide-out-right');

    activeOriginalImg.classList.add('slide-out-left');
    nextOriginalImg.classList.add('slide-in-right');
    nextOriginalImg.classList.add('active');


    activeBlurredImg.classList.add('slide-out-left');

    activeBlurredImg.classList.add('active');

    nextBlurredImg.classList.add('slide-in-right');
    nextBlurredImg.classList.add('active');


    activeText.classList.remove('active');
    nextText.classList.add('active');
    activeDot.classList.remove('active');
    nextDot.classList.add('active');

    /*Clean all */
    activeOriginalImg.classList.remove('active');
    activeBlurredImg.classList.remove('active');

}

function showPrevSlide() {
    var activeOriginalImg = promoSlider.querySelector('.container-original-images img.active');
    var activeBlurredImg = promoSlider.querySelector('.container-blurred-images img.active');
    var activeDot = promoSlider.querySelector('.container-dots span.active');
    var activeText = promoSlider.querySelector('.container-text .text.active');
    var prevOriginalImg = activeOriginalImg.previousElementSibling;
    var prevBlurredImg = activeBlurredImg.previousElementSibling;
    var prevDot = activeDot.previousElementSibling;
    var prevText = activeText.previousElementSibling;
    if (activeOriginalImg === promoSlider.querySelector(".container-original-images").firstElementChild) {
        prevOriginalImg = promoSlider.querySelector(".container-original-images").lastElementChild;
        /* WARNING: If you change container-blurred-images and add not img (like filter div for container-original-images,
         you should add check for img lastElementChild */
        prevBlurredImg = promoSlider.querySelector(".container-blurred-images").lastElementChild;
        prevDot = promoSlider.querySelector('.container-dots').lastElementChild;
        prevText = promoSlider.querySelector(".container-text").lastElementChild;
    }
    activeOriginalImg.classList.remove('slide-in-left');
    activeOriginalImg.classList.remove('slide-in-right');
    activeOriginalImg.classList.remove('slide-out-left');
    activeOriginalImg.classList.remove('slide-out-right');

    prevOriginalImg.classList.remove('slide-in-left');
    prevOriginalImg.classList.remove('slide-in-right');
    prevOriginalImg.classList.remove('slide-out-left');
    prevOriginalImg.classList.remove('slide-out-right');


    activeBlurredImg.classList.remove('slide-in-left');
    activeBlurredImg.classList.remove('slide-in-right');
    activeBlurredImg.classList.remove('slide-out-left');
    activeBlurredImg.classList.remove('slide-out-right');

    prevBlurredImg.classList.remove('slide-in-left');
    prevBlurredImg.classList.remove('slide-in-right');
    prevBlurredImg.classList.remove('slide-out-left');
    prevBlurredImg.classList.remove('slide-out-right');

    activeOriginalImg.classList.add('slide-out-right');
    prevOriginalImg.classList.add('slide-in-left');
    prevOriginalImg.classList.add('active');


    activeBlurredImg.classList.add('slide-out-right');

    activeBlurredImg.classList.add('active');

    prevBlurredImg.classList.add('slide-in-left');
    prevBlurredImg.classList.add('active');


    activeText.classList.remove('active');
    prevText.classList.add('active');
    activeDot.classList.remove('active');
    prevDot.classList.add('active');

    /*Clean all */
    activeOriginalImg.classList.remove('active');
    activeBlurredImg.classList.remove('active');
}

function showSlideByNumber(slideNumber) {
    var activeOriginalImg = promoSlider.querySelector('.container-original-images img.active');
    var activeBlurredImg = promoSlider.querySelector('.container-blurred-images img.active');
    var activeDot = promoSlider.querySelector('.container-dots span.active');
    var activeText = promoSlider.querySelector('.container-text .text.active');
    var selectedOriginalImg = promoSlider.querySelector('[data-promo-slider-original-img="' + slideNumber + '"]');
    var selectedBlurredImg = promoSlider.querySelector('[data-promo-slider-blurred-img="' + slideNumber + '"]');
    var selectedDot = promoSlider.querySelector('[data-promo-slider-dot="' + slideNumber + '"]');
    var selectedText = promoSlider.querySelector('[data-promo-slider-text="' + slideNumber + '"]');
    /* Remove old sliding classes */
    for (var ois = 0; ois < promoOriginalImg.length; ois++) {
        promoOriginalImg[ois].classList.remove('slide-in-left');
        promoOriginalImg[ois].classList.remove('slide-in-right');
        promoOriginalImg[ois].classList.remove('slide-out-left');
        promoOriginalImg[ois].classList.remove('slide-out-right');
    }
    for (var bis = 0; bis < promoBlurredImg.length; bis++) {
        promoBlurredImg[bis].classList.remove('slide-in-left');
        promoBlurredImg[bis].classList.remove('slide-in-right');
        promoBlurredImg[bis].classList.remove('slide-out-left');
        promoBlurredImg[bis].classList.remove('slide-out-right');
    }

    /* Check selected position for get left or right slide */

    if (activeOriginalImg.getAttribute ('data-promo-slider-original-img').slice(-1) <= slideNumber.slice(-1)) {

        activeOriginalImg.classList.add('slide-out-left');
        activeBlurredImg.classList.add('slide-out-left');
        selectedOriginalImg.classList.add("slide-in-right");
        selectedBlurredImg.classList.add("slide-in-right");
    } else {
        activeOriginalImg.classList.add('slide-out-right');
        activeBlurredImg.classList.add('slide-out-right');
        selectedOriginalImg.classList.add("slide-in-left");
        selectedBlurredImg.classList.add("slide-in-left");
    }


    activeOriginalImg.classList.remove('active');
    activeBlurredImg.classList.remove('active');
    selectedOriginalImg.classList.add("active");
    selectedBlurredImg.classList.add("active");


    activeText.classList.remove('active');
    selectedText.classList.add('active');
    activeDot.classList.remove('active');
    selectedDot.classList.add('active');


}
